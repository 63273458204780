/* cyrillic */
@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27pmO7&skey=96867d716c89840e&v=v19) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27rWO7&skey=96867d716c89840e&v=v19) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27rGO7&skey=96867d716c89840e&v=v19) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27omO7&skey=96867d716c89840e&v=v19) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_d_QG5XyEAk4A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/figtree/v2/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_d_QG5ZyEA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,400&display=swap');
  
body {
    font-family: 'Proxima Nova';
    font-weight: 450;
    line-height: 24px;
    font-size: 16px;
}
.wrapper {
    padding: 20px;
}

/* width */
::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3284C6;
    border-radius: 40px;
    width: 11px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3284C6;
    border-radius: 40px;
}

.list-item-active {
    background: #3078B0;
}

.list-item:hover {
    background: #3078B0;
}

.list-button {
    transition: all 0.2s !important;
}

.list-button:hover {
    background: #3078B0;
    background-color: rgba(0, 0, 0, 0) !important;
    padding-left: 35px;
    transition: all 0.2s;
}

.MuiDataGrid-row {
    border-bottom: none;
}

.MuiDataGrid-row:hover {
    border-bottom: none;
    color: #1eb1e0;
}

.MuiDataGrid-cell {
    border-bottom: none !important;
}

.MuiDataGrid-row.odd {
    background-color: rgba(0, 0, 0, 0.04);
}

#profile-button {
    background-color: rgba(30, 177, 224, 0.04);
    border-radius: 22px;
    min-width: auto;
    padding: 10px 10px;
}

.align-right {
    text-align: right;
}

.pr-10 {
    padding-right: 10px;
}

.MuiInputBase-input {
    font-size: 15px !important;
}

.MuiPaper-elevation.MuiDialog-paper {
    max-width: 800px;
}